@font-face {
    font-family: 'Hennessy';
    src: url('./HennessySans1765_W.woff2') format("woff2");
}

.app {
    font-family: 'Hennessy';

	position: relative;
	width: 100vw;
	height: 177.78vw; /* 16/9 = 1.778 */
	max-height: 100vh;
	max-width: 56.25vh;
	margin: auto;
	position: absolute;
	top:0;
	bottom:0; /* vertical center */
	left:0;right:0; /* horizontal center */
	opacity: 1;
}

@media (max-aspect-ratio: 9/16) {
	.app{
		width: 100%;
    	height: 100%; /* 16/9 = 1.778 */
    	max-height: 100%;
    	max-width: 100%;
	}
}

.nameHanzi {
	font-size: 2em;
}

.nameLatin {
	font-size: 1em;
}

.dataPlaceNamePosition{
	position: absolute;
	width: 100%;
}

.dataPlaceName{
	position: relative;
	width: 80%;
	aspect-ratio: 16/4;
	left: -40%;
	background-position: center 45%;
	background-size: cover;
	text-align: center;
	color:white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

ul {
	padding: 0;
}

ul li {
	list-style-type: none;
}

.preshow-ss-position{
	display: flex;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top:-7%;
	left: 0;
	text-align: center;
}

.preshow-ss{
	position: relative;
	display: block;
	top: 0%;
	left: 0;
	width: 100%;
	/*height: 100%;  fit-content */
	padding-top: 200%;
	/*aspect-ratio: 1/2;*/
	background-color: blue;
	background-size: 600%;
	background-repeat: no-repeat;
	
	/*background-position: 14.28% 100%;*/
}

@media (max-aspect-ratio: 9/16) {
	.preshow-ss{
		/*height: 100%;
		height: 114%;
		width: auto;
		padding-top: 0%;
		padding-left: 120%;*/
		/*max-width: 100vh;*/
		/*top: 7%;*/
		width: 10vh;
		height: 20vh;
		transform-origin: top;
		transform: scale(500%);
		padding-top: 0;

	}
	.preshow-ss-position{
		top:0%
	}
}